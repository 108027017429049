import '../../styles/globals.css'
import type { AppProps } from 'next/app'
import { RecoilRoot } from "recoil"
// import { useCallback, useEffect, useState } from 'react'

function MyApp({ Component, pageProps }: AppProps) {
  // const [windowSize, setWindowSize] = useState<{ width?: number, height?: number }>({ width: 0, height: 0 })

  // const resizeHandler = useCallback(() => {
  //   if (typeof window !== "undefined") {
  //     setWindowSize({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     })
  //   }
  // }, []);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     window.addEventListener("resize", resizeHandler);
  //     return () => window.removeEventListener("resize", resizeHandler)
  //   }
  // }, []);

  return <RecoilRoot>
    <Component {...pageProps} />
    {/* <div style={{ position: "fixed", top: 0, left: 0, width: "auto", height: "25px", color: "white" }}>window width={windowSize.width}, height={windowSize.height}</div> */}
  </RecoilRoot>
}

export default MyApp
